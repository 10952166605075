import type {
  LexicalCommand,
  LexicalEditor,
  NodeKey,
  NodeSelection,
  RangeSelection
} from 'lexical';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  $setSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  createCommand,
  DRAGSTART_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';

import { $isImageNode } from './ImageNode';
import { ImageIdentity } from '../plugins/ImagesPlugin';
import { uploadCommunityImage } from '@/services/communitiesService';
import { showErrorToast } from '../../ToastContainer';
import useOutsideComponentClickDetect from '@/hooks/useOutsideComponentClickDetect';
import classNames from 'classnames';
import { hasBase64Content } from '@/utility/member/helper';
import NPLButton, { BTN_HIERARCHY } from '@/components/npl/NPLButton';
import HeadlessDropdown from '../../Form/HeadlessDropdown/HeadlessDropdown';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import PopOverModal from '../../PopOverModal';
import config from '@/utility/config';
import ProgressBar from '@/components/common/ProgressBar';
import { t } from '@/utility/localization';

export const RIGHT_CLICK_IMAGE_COMMAND: LexicalCommand<MouseEvent> =
  createCommand('RIGHT_CLICK_IMAGE_COMMAND');

enum ImageUploadingState {
  NotUploading = 'NotUploading',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Loaded = 'Loaded',
  Failed = 'Failed'
}

const WIDTH_SIZES = [
  {
    label: 'Small',
    value: '25%'
  },
  {
    label: 'Medium',
    value: '50%'
  },

  {
    label: 'Large',
    value: '100%'
  }
];

const imageUrlToBase64 = async (
  url: string
): Promise<string | ArrayBuffer> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

function LazyImage({
  altText,
  className,
  imageRef,
  src,
  height,
  width,
  imageIdentity,
  onSrcChange,
  onWidthChange,
  selfDelete,
  isEditable = true
}: {
  altText?: string;
  className: string | null;
  height: 'inherit' | number | string;
  imageRef: { current: null | HTMLImageElement };
  width: number | string;
  src: string;
  imageIdentity?: ImageIdentity;
  nodeKey: string;
  onSrcChange: (newSrc: string) => void;
  onWidthChange: (width: number | string) => void;
  selfDelete: () => void;
  isEditable?: boolean;
}): JSX.Element | null {
  const [imageUploadingState, setImageUploadingState] =
    useState<ImageUploadingState>(ImageUploadingState.NotUploading);

  const [currentImageSource, setCurrentImageSource] =
    useState<string>(src);
  const originalImageSourceRef = useRef<string>(src);
  const { isGtEqLg } = useWindowWidthContext();
  const [isImageResizingOpen, setIsImageResizingOpen] =
    useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    if (imageIdentity && isEditable) {
      const uploadImage = async (base64: string | ArrayBuffer) => {
        // Check if already uploading or uploaded, then don't proceed
        if (
          imageUploadingState === ImageUploadingState.Uploading ||
          imageUploadingState === ImageUploadingState.Uploaded
        ) {
          return;
        }
        setImageUploadingState(ImageUploadingState.Uploading);
        setProgress(0); // Start progress at 0

        // Simulate progress
        const simulateProgress = () => {
          setProgress((prevProgress) => {
            const nextProgress = prevProgress + 1.5;
            if (nextProgress < 100) {
              setTimeout(simulateProgress, 20); // Adjust timing to suit
            }
            return nextProgress;
          });
        };
        simulateProgress();

        const { data, error } = await uploadCommunityImage(
          imageIdentity.communityId,
          {
            base64: base64,
            entityType: imageIdentity.entityType,
            entityId: imageIdentity.entityId,
            mailType: imageIdentity.mailType
          }
        );
        if (error) {
          showErrorToast(error);
          setImageUploadingState(ImageUploadingState.Failed);
          onSrcChange('');
          selfDelete();
        } else {
          setCurrentImageSource(data.imageUrl);
          onSrcChange(data.imageUrl);
          if (progress === 100) {
            setImageUploadingState(ImageUploadingState.Uploaded);
          } else {
            // wait 1 sec and then set progress to 100
            setTimeout(() => {
              setProgress(100);
              setImageUploadingState(ImageUploadingState.Uploaded);
            }, 1000);
          }
        }
      };
      const uploadImageFromUrl = async (url: string) => {
        const base64 = await imageUrlToBase64(url);
        uploadImage(base64);
      };
      if (hasBase64Content(currentImageSource)) {
        uploadImage(currentImageSource);
      } else {
        //if this current image source is not from our bucket, we should upload it
        if (!currentImageSource.includes(config.imagesBasePath)) {
          uploadImageFromUrl(currentImageSource);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-full justify-center">
      <div
        className="relative my-12 flex h-full w-full justify-center transition-all"
        style={{
          width: width
        }}>
        <img
          className={classNames(className || undefined, 'w-full')}
          src={originalImageSourceRef.current}
          alt={altText}
          ref={imageRef}
          style={{
            height
          }}
          draggable="false"
        />
        {isEditable && (
          <>
            {isGtEqLg ? (
              <div className="t-0 l-0 absolute z-1 h-full w-full rounded-12 bg-npl-transparent-black-50 opacity-0 transition-all hover:opacity-100">
                <div className="mr-8 mt-8 flex justify-end">
                  <HeadlessDropdown
                    placement="bottom-start"
                    renderField={() => (
                      <NPLButton
                        hierarchy={BTN_HIERARCHY.OUTLINE}
                        leadIcon="expand-width"
                        size="md"
                        rounded
                        onClick={() => {
                          setIsImageResizingOpen(true);
                        }}
                      />
                    )}
                    renderOptions={({ closeDropdown }) => {
                      return (
                        <ul className="w-[200px] rounded-12 bg-white-default p-8">
                          {WIDTH_SIZES.map((option) => (
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                            <li
                              key={option.value}
                              className={classNames(
                                'flex w-full items-center space-x-12 rounded-8 px-8 py-10 text-label-md  text-dark-1b',
                                {
                                  'cursor-pointer': width !== option.value,
                                  'hover:bg-npl-neutral-light-solid-3':
                                    width !== option.value
                                }
                              )}
                              value={option.value}
                              onClick={async () => {
                                closeDropdown();
                                onWidthChange(option.value);
                              }}>
                              <span
                                className={classNames(
                                  'font-poppins text-label-md',
                                  {
                                    'text-npl-text-icon-on-light-surface-secondary':
                                      width === option.value
                                  }
                                )}>
                                {option.label}
                              </span>
                            </li>
                          ))}
                        </ul>
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="t-0 l-0 absolute z-1 h-full w-full">
                  <div className="mr-8 mt-8 flex justify-end">
                    <NPLButton
                      hierarchy={BTN_HIERARCHY.OUTLINE}
                      leadIcon="expand-width"
                      size="md"
                      rounded
                      onClick={() => {
                        setIsImageResizingOpen(true);
                      }}
                    />
                  </div>
                </div>
                <PopOverModal
                  showCloseIcon={false}
                  open={isImageResizingOpen}
                  onClose={() => {
                    setIsImageResizingOpen(false);
                  }}>
                  <div>
                    <div>
                      {WIDTH_SIZES.map((option) => (
                        <div
                          key={option.value}
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            setIsImageResizingOpen(false);
                            onWidthChange(option.value);
                          }}
                          className={classNames(
                            'flex px-8 py-14 text-label-lg',
                            {
                              'text-npl-text-icon-on-light-surface-secondary':
                                width === option.value
                            }
                          )}>
                          <span>{option.label}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </PopOverModal>
              </>
            )}
          </>
        )}

        {imageUploadingState === ImageUploadingState.Uploaded &&
          progress === 100 && (
            <img
              className={classNames(
                className,
                'absolute top-0 !m-0 h-full w-full'
              )}
              src={currentImageSource}
              alt={altText}
              ref={imageRef}
              style={{
                height,
                width: '100%'
              }}
              draggable="false"
            />
          )}

        {imageUploadingState === ImageUploadingState.Uploading && (
          <>
            <div className="absolute top-0 h-full w-full bg-npl-transparent-white-75">
              <div className="mt-32 space-y-5">
                <h1 className="text-center text-body-md font-medium text-npl-text-icon-on-light-surface-primary">
                  {t('uploading')}...
                </h1>

                <ProgressBar
                  value={progress}
                  className="absolute top-0 mx-auto flex h-8 w-[50%]"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default function ImageComponent({
  src,
  altText,
  nodeKey,
  height,
  width,
  imageIdentity
}: {
  altText?: string;
  height: 'inherit' | number | string;
  nodeKey: NodeKey;
  resizable: boolean;
  src: string;
  width?: 'inherit' | number | string;
  imageIdentity?: ImageIdentity;
}): JSX.Element {
  const imageRef = useRef<null | HTMLImageElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);
  const [editor] = useLexicalComposerContext();
  const [selection, setSelection] = useState<
    RangeSelection | NodeSelection | null
  >(null);
  const activeEditorRef = useRef<LexicalEditor | null>(null);

  const selfDelete = () => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isImageNode(node)) {
        node.remove();
      }
    });
  };

  const onDelete = useCallback(
    (payload: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        selfDelete();
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSelected, nodeKey]
  );

  const onEnter = useCallback(
    (event: KeyboardEvent) => {
      const latestSelection = $getSelection();
      const buttonElem = buttonRef.current;

      if (
        isSelected &&
        $isNodeSelection(latestSelection) &&
        latestSelection.getNodes().length === 1
      ) {
        const imageNode = $getNodeByKey(nodeKey);
        const paragraphNode = $createParagraphNode();
        imageNode?.insertAfter(paragraphNode, true);
        paragraphNode.select();
        if (buttonElem !== null && buttonElem !== document.activeElement) {
          event.preventDefault();
          buttonElem.focus();
          return true;
        }
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSelected]
  );

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (buttonRef.current === event.target) {
        $setSelection(null);
        editor.update(() => {
          setSelected(true);
          const parentRootElement = editor.getRootElement();
          if (parentRootElement !== null) {
            parentRootElement.focus();
          }
        });
        return true;
      }
      return false;
    },
    [editor, setSelected]
  );

  const onClick = useCallback(
    (payload: MouseEvent) => {
      const event = payload;

      if (event.target === imageRef.current) {
        if (event.shiftKey) {
          setSelected(!isSelected);
        } else {
          clearSelection();
          setSelected(true);
        }
        return true;
      }

      return false;
    },
    [isSelected, setSelected, clearSelection]
  );

  const onRightClick = useCallback(
    (event: MouseEvent): void => {
      editor.getEditorState().read(() => {
        const latestSelection = $getSelection();
        const domElement = event.target as HTMLElement;
        if (
          domElement.tagName === 'IMG' &&
          $isRangeSelection(latestSelection) &&
          latestSelection.getNodes().length === 1
        ) {
          editor.dispatchCommand(
            RIGHT_CLICK_IMAGE_COMMAND,
            event as MouseEvent
          );
        }
      });
    },
    [editor]
  );

  useEffect(() => {
    let isMounted = true;
    const rootElement = editor.getRootElement();
    const unregister = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        if (isMounted) {
          setSelection(
            editorState.read(() => $getSelection() as NodeSelection)
          );
        }
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor;
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<MouseEvent>(
        CLICK_COMMAND,
        onClick,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<MouseEvent>(
        RIGHT_CLICK_IMAGE_COMMAND,
        onClick,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        DRAGSTART_COMMAND,
        (event) => {
          if (event.target === imageRef.current) {
            // TODO This is just a temporary workaround for FF to behave like other browsers.
            // Ideally, this handles drag & drop too (and all browsers).
            event.preventDefault();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        onEnter,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        onEscape,
        COMMAND_PRIORITY_LOW
      )
    );

    rootElement?.addEventListener('contextmenu', onRightClick);

    return () => {
      isMounted = false;
      unregister();
      rootElement?.removeEventListener('contextmenu', onRightClick);
    };
  }, [
    clearSelection,
    editor,
    isSelected,
    nodeKey,
    onDelete,
    onEnter,
    onEscape,
    onClick,
    onRightClick,
    setSelected
  ]);

  const onSrcChange = (newSrc: string) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if (node !== null && $isImageNode(node)) {
        node.setSrc(newSrc);
      }
    });
  };

  const onWidthChange = (width: string) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if (node !== null && $isImageNode(node)) {
        node.setWidth(width);
      }
    });
  };

  useOutsideComponentClickDetect(imageRef, () => {
    clearSelection();
  });

  const draggable = isSelected && $isNodeSelection(selection);
  return (
    <Suspense fallback={null}>
      <>
        <div draggable={draggable}>
          <LazyImage
            className={
              isSelected
                ? `focused ${
                    $isNodeSelection(selection) ? 'draggable' : ''
                  }`
                : null
            }
            src={src}
            altText={altText}
            imageRef={imageRef}
            height={height}
            width={width}
            imageIdentity={imageIdentity}
            nodeKey={nodeKey}
            onSrcChange={onSrcChange}
            onWidthChange={onWidthChange}
            selfDelete={selfDelete}
            isEditable={editor?.isEditable()}
          />
        </div>
      </>
    </Suspense>
  );
}
