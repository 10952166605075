import * as ProgressPrimitive from '@radix-ui/react-progress';
import classNames from 'classnames';
import { useEffect, useState, forwardRef, ElementRef } from 'react';

const ProgressBar = forwardRef<
  ElementRef<typeof ProgressPrimitive.Root>,
  {
    value: number;
    className?: string;
    filledColorClassName?: string;
    darkMode?: boolean;
  }
>(
  (
    {
      className,
      filledColorClassName = 'bg-npl-yellow-light-solid-9',
      darkMode = false,
      value,
      ...props
    },
    ref
  ) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const timer = setTimeout(() => setProgress(value), 500);
      return () => clearTimeout(timer);
    }, [value]);

    return (
      <ProgressPrimitive.Root
        ref={ref}
        className={classNames(
          'relative h-2 overflow-hidden rounded-full ',
          darkMode
            ? 'bg-npl-transparent-white-25'
            : 'bg-npl-transparent-black-25',
          className
        )}
        {...props}>
        <ProgressPrimitive.Indicator
          className={classNames(
            'h-full w-full flex-1 transition-all',
            filledColorClassName
          )}
          style={{ transform: `translateX(-${100 - (progress || 0)}%)` }}
        />
      </ProgressPrimitive.Root>
    );
  }
);
ProgressBar.displayName = ProgressPrimitive.Root.displayName;

export default ProgressBar;
